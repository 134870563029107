import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { localeFiles } from '../data/constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icon from '../components/atoms/Icon';
import Button from '../components/atoms/Button';
import PageDefault from '../components/templates/PageDefault';
import StaticContent from '../components/templates/StaticContent';
import { pages } from '../data/pages';
import styles from '../styles/pages/Home.module.scss';

const Home = () => {
  const { t } = useTranslation(['common', 'pages']);
  const router = useRouter();
  const { locale } = router;
  const slug = 'home';
  const sections = (!!locale && pages[locale][slug]) || [];

  return (
    <>
      <Head>
        <title>
          {t(`pages:${slug}:title`)} | {t('app-name')}
        </title>
        <meta name="description" content={t(`pages:${slug}:title`)} />
      </Head>

      <PageDefault
        initial
        className={styles.page_home}
        stage={sections[0]?.stage}
      >
        {sections.map((section: any, i: number) => (
          <StaticContent
            key={String(i)}
            className={section.class}
            h1={section.h1}
            h2={section.h2}
            p={section.children}
            s={section.s}
            imgs={section.imgs}
            html={section.html}
            full={section.full || (section.children || []).length === 1}
            hero={section.hero}
            center={section.center}
            small={section.narrow}
            columns={section.columns}
            figures={section.figures}
          >
            {i === sections.length - 2 && (
              <>
                <Col xs={8} md={4} lg={2} className="offset-md-2 offset-lg-3 ">
                  <Button
                    dark
                    large
                    colored
                    icon={'arrow-big-right'}
                    label={t('common:cta-subscribe')}
                    href="https://venuescout-20844017.hubspotpagebuilder.com/venuescout-newsletter-singup-0"
                  />
                </Col>
              </>
            )}

            {i === sections.length - 1 && (
              <>
                <Col xs={8} md={4} lg={2} className="offset-md-2 offset-lg-3 ">
                  <div className={styles.socialMediaButtons}>
                    <Button
                      icon="linkedin-big"
                      href="https://www.linkedin.com/company/venuescoutplatform "
                    />
                    <Button
                      icon="twitter-big"
                      href="https://twitter.com/_venuescout"
                    />
                    {/* <Button
                      icon="facebook-big"
                      href="http://www.facebook.com/venuescoutplatform"
                    /> */}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                </Col>
              </>
            )}
          </StaticContent>
        ))}

        <Row className="justify-content-center">
          <Col className={styles.partners}>
            <div>
              <h3>{t('common:partners')}</h3>
              <ul>
                <li>
                  <div>
                    <Image
                      src="/assets/logos/tsnn.svg"
                      height={136}
                      width={136}
                      alt="TSNN Logo"
                    />
                  </div>
                  <small>{t('common:official-partner')}</small>
                </li>
                <li>
                  <div>
                    <Icon name="ifes" />
                  </div>
                  <small>{t('common:official-partner')}</small>
                </li>

                <li>
                  <div>
                    <Image
                      src="/assets/logos/mash-media.png"
                      height={136}
                      width={136}
                      objectFit="contain"
                      alt="Mash Media Logo"
                    />
                  </div>
                  <small>{t('common:media-partner')}</small>
                </li>
              </ul>
              <br />
              <br />
            </div>

            <div>
              <h3>{t('common:member-of')}</h3>
              <ul>
                {[
                  'ufi',
                  'ifes-member.png',
                  'icca.jpg',
                  'iaee.png',
                  'hkecia.jpg',
                  'ieia.jpg',
                ].map((str, i) => (
                  <li key={i}>
                    <div>
                      {str.indexOf('.') > -1 ? (
                        <Image
                          src={`/assets/logos/${str}`}
                          height={136}
                          width={136}
                          objectFit="contain"
                          alt="" //{`${str.split("-")[0]?.toUpperCase()} Member Logo`}
                        />
                      ) : (
                        <Icon name={str} />
                      )}
                    </div>
                    {/* <small>{t('common:member')}</small> */}
                  </li>
                ))}
              </ul>
              <br />
              <br />
            </div>
          </Col>
        </Row>
      </PageDefault>
    </>
  );
};

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale!, localeFiles)),
    },
  };
}

export default Home;
